import React, {useState, useEffect} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {rxrBlackColor, rxrGreyColor, rxrLightGreyColor, rxrTealColor, shadowSoft} from '../../assets/styles/color'
import {spaceExtraExtraSmall, spaceLarge, spaceMedium, spaceSmall} from '../../assets/styles/spacing'
import ResidentProfileImage from '../ResidentProfiles/ResidentProfileImage'
import PropTypes from 'prop-types'
import useResidentOrChatParticipantLookup from '../hooks/useResidentOrChatParticipantLookup'
import RXRIcon from '../RXRIcon'
import {IconButton} from '@material-ui/core'
import {fontSizeMediumLarge} from '../../assets/styles/typography'

const MAX_CHARACTERS = 100

function MessageCard(props) {
  const classes = useStyles()
  const [isReady, setIsReady] = useState(false)
  const {getResidentOrChatParticipant} = useResidentOrChatParticipantLookup()

  useEffect(() => {
    setIsReady(true)
  }, [])

  const resident = getResidentOrChatParticipant(props.message.chatUserId)

  const content = props.message.content || ''
  const contentFormatted = content.length > MAX_CHARACTERS ? content.substr(0, MAX_CHARACTERS - 3) + '...' : content

  return (
    <div className={`${classes.container} ${isReady ? classes.ready : ''}`}>
      <IconButton className={classes.closeButton} onClick={props.onClose}>
        <RXRIcon icon={RXRIcon.CLOSE} />
      </IconButton>
      <div className={classes.headerRow}>
        <ResidentProfileImage className={classes.profileImage} residentId={resident.id} />
        <span className={classes.residentName}>{resident.displayName}</span>
      </div>
      <p className={classes.message}>{contentFormatted}</p>
      <div>
        <span className={classes.link} onClick={props.onOpen}>
          Open message
        </span>
      </div>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    borderRadius: 4,
    backgroundColor: rxrLightGreyColor,
    padding: spaceSmall,
    boxShadow: shadowSoft,
    border: `1px solid ${rxrGreyColor}`,
    opacity: 0,
    transform: `translate(0px, -20px)`,
    transition: `all 0.2s`,
  },

  ready: {
    opacity: 1,
    transform: `translate(0px, 0px)`,
  },

  headerRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },

  profileImage: {
    height: spaceMedium,
    width: spaceMedium,
    marginRight: spaceSmall,
  },

  residentName: {
    fontSize: fontSizeMediumLarge,
    color: rxrBlackColor,
  },

  closeButton: {
    position: 'absolute',
    top: spaceExtraExtraSmall,
    right: spaceExtraExtraSmall,
    width: spaceLarge,
    height: spaceLarge,
  },

  message: {
    margin: `${spaceSmall}px 0`,
    width: '380px', // totally arbitrary width
    height: '42px', // exactly 2 lines of text
  },

  link: {
    color: rxrTealColor,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}))

MessageCard.propTypes = {
  message: PropTypes.any.isRequired,
  onClose: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
}

export default MessageCard
